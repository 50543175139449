import React from 'react'
import Fade from 'react-reveal/Fade';
import './Opioid.css'
import {Helmet} from "react-helmet";


const Opioid = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Opioid Addiction - San Rafael Rehab</title>
            <link rel="canonical" href="https://www.sanrafaelrehab.org/opioid" />
        </Helmet>

        <div className='opioid'>

                <div className="opioid-content">
                    <Fade top>
                    <h1>OPIOID ADDICTION</h1>
                    <h3>SUBSTANCE ABUSE</h3>

                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Opioid
