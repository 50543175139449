import React from 'react'
import Fade from 'react-reveal/Fade';
import './DetoxMethamphetamine.css'
import {Helmet} from "react-helmet";

const DetoxMethamphetamine = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Methamphetamine Detox - San Rafael Rehab</title>
            <link rel="canonical" href="https://www.sanrafaelrehab.org/detox-methamphetamine" />
        </Helmet>

        <div className='detox-methamphetamine'>

                <div className="detox-methamphetamine-content">
                    <Fade top>
                    <h1>Methamphetamine</h1>
                    <h2>Detox Program</h2>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default DetoxMethamphetamine
