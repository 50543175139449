import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";

const Hero = () => {
    return (
<>        
<div className='hero'>
    <div className="hero-container">

        <div className="content">
                <h1>Your First Step to Healing at San Rafael Rehab, Nestled in the <br />Heart of Marin County, CA.</h1>
                <h2>San Rafael Rehab</h2>
                <p>Our serene environment, expert care team, and evidence-based treatments are designed to help you safely transition into recovery.
                </p>

                <div className='heroBtn'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/contact'>
                    <button>Find Help Now</button>
                    </Link>
                    </LinkRoll>

                    <LinkRoll activeClass="active" to="LearnMore" spy={true} smooth={true} duration={500}>
                    <button>Learn More</button>
                    </LinkRoll>

                </div>


        </div>
    </div>
</div>
</>

    )
}

export default Hero