import React from "react";
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import './Contact.css'
import Team from "../../assets/contact-us-hero.webp";


const Contact = () => {

    return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Contact Us - San Rafael Rehab</title>
                <link rel="canonical" href="https://www.sanrafaelrehab.org/contact" />
            </Helmet>

            <div className='contact main-menu'>

                        <div className="contact-content container">
                        <div className='team'>
                            <img src={Team} alt="Calm man in front of the sea" loading="lazy" />
                        </div>

                        <Fade top>
                        <div className="contact-caption">
                            <h1>HAVE SOME QUESTIONS?</h1>
                        </div>
                        </Fade>
                        </div>
                    </div>



            </>
    )

    }
export default Contact




