import React from 'react'
import Fade from 'react-reveal/Fade';
import './Benzodiazepines.css'
import {Helmet} from "react-helmet";

const Benzodiazepines = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Benzodiazepines Addiction - San Rafael Rehab</title>
            <link rel="canonical" href="https://www.sanrafaelrehab.org/benzodiazepines" />
        </Helmet>

        <div className='benzodiazepines'>
                <div className="benzodiazepines-content">
                    <Fade top>
                        <h1> BENZODIAZEPINES ADDICTION</h1>
                        <h3>SUBSTANCE ABUSE</h3>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Benzodiazepines
