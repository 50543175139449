import React from 'react'
import Fade from 'react-reveal/Fade';
import './DetoxHeroin.css'
import {Helmet} from "react-helmet";

const DetoxHeroin = () => {
    return (
        <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Heroin Detox - San Rafael Rehab</title>
        <link rel="canonical" href="https://www.sanrafaelrehab.org/detox-heroin" />
    </Helmet>

        <div className='detox-heroin'>

                <div className="detox-heroin-content">
                    <Fade top>
                    <h1>Heroin Detox</h1>
                    <h2>Detox Program</h2>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default DetoxHeroin
