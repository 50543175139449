import React, { useRef } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';


import './ContactForm.css';
import emailjs from 'emailjs-com'


const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


            // Popup message after send button is clicked
            const feedbackEl = document.querySelector(".feedback");
            feedbackEl.setAttribute("class", "feedback");
            setTimeout(function() {
            feedbackEl.setAttribute("class", "feedback hidden");
            }, 3000);




        //    Service Id        Template Id                 Public Key (Account Tab) 
        emailjs.sendForm('service_hsunksm', 'template_rxc8sbg', form.current, 'Rj5e7bWJla-kOEL0H')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
    };

    return (
        <>
<div className="contactform">


<div className="contact-header container">
            <h1>DO YOU NEED URGENT HELP?</h1>
            <p>Our compassionate team of experts is dedicated to guiding you every step of the way, offering personalized care and understanding. Whether you’re overcoming alcohol, opioids, or other substance dependencies, we are here to provide the support you need. Take the first step toward reclaiming your life—contact us today to start your journey to a healthier, brighter future.</p>
</div>


<section className="container">
<div>
      <div class="footer_get_touch_inner">
          <div class="get_form_inner">
            <div class="get_form_inner_text">
              <h1>Get In Touch</h1>
              <p><i>We'll keep your contact information strictly confidential.</i></p><br/>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div class="inputs">
                <input type="text" name='name' placeholder='Your Name' required /> <br/>
                <input type="text" name='email' placeholder='Your Email' required /> <br/>
                <input type="tel" name='phone' placeholder='Your Phone' required /> <br/>
                <input type="text" name='subject' placeholder='Subject' required /> <br/>

              </div>
              <div>
                <textarea name="message" placeholder="How can we help?" cols="30" rows="10" required></textarea>
                <input type="submit" value="Submit" />
              </div>

              <div className="textarea2 feedback hidden">
                  <textarea name="message2" cols="30" rows="3" required>Message Sent to San Rafael Rehab</textarea>
                </div>


            </form>
    </div>
</div>
</div>
</section>


<div className="contact-cards-container">
    
    <div class="get_form_inner2">
            <h2>CALL US</h2>
            <p><AiOutlinePhone /><a href="tel:3235243328"> +1 (323) 524-3328</a></p>
    </div>

    <div class="get_form_inner2">
            <h2>EMAIL</h2>
            <p><AiOutlineMail /> Info@SanRafaelRehab.com </p><br />
    </div>

    <div class="get_form_inner2">
            <h2>LOCATION</h2>
            <p><BsFillPinMapFill /> 25 Mitchell Blvd, Suite 2, San Rafael, CA. 94903  </p>
    </div>

</div>
    
</div>
        </>
    )
}

export default ContactForm