import React from "react";
import "./Topbar.css";
import { Link } from "react-router-dom";
import logo from "../../assets/nav-logo.png";
import { MdLocationPin } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { AiOutlinePhone } from "react-icons/ai";

const Topbar = () => {
  return (
    <div className="topbar" name='top'>
      <div className="topbar-content container">
        
        <div className="topbar-logo topbar-hide" >
          <Link to="/">
            <img src={logo} alt="Palm Springs Rehab Logo" loading="lazy" />
          </Link>
        </div>

        <div className="address bar-box1 topbar-hide">
          <span>
            <MdLocationPin />
          </span>
          <div className="address-header">
            <span><b><p>Location:</p></b></span>
            <p>25 Mitchell Blvd, Suite 2, San Rafael, CA. 94903</p>
          </div>
        </div>

        <div className="email bar-box2">
          <span>
            <FiMail />
          </span>
          <div>
            <span><b><p>Email Address:</p></b></span>
            <p>Info@SanRafaelRehab.com</p>
          </div>
        </div>

        <div className="number bar-box3">
          <span>
            <AiOutlinePhone />
          </span>
          <div>
            <span><b><p>Phone Number:</p></b></span>
            <p><a href="tel:3103438684"> +1 (310) 343 8684</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
