import React from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import logo from '../../assets/nav-logo-wh.png'
import './FooterStyles.css'
import {Link} from 'react-router-dom'
import { Link as LinkRoll } from 'react-scroll'
import DHCS from "../../assets/DHCS-logo2.png";
// import JointCommission from "../../assets/joint_commission_logo.webp";

const Footer = () => {
    return (

<>
        <div className='footer'>
            <div className="container">
                <div className="top">
                    <div className="logo-footer">
                    <img src={logo} alt="San Rafael Rehab Logo" loading="lazy"/>
                    </div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <BsFillArrowUpCircleFill className='footer-icon' />
                    </LinkRoll>
                </div>
                <div className="col-container">

                    <div className="col">
                        <h3>Navigation</h3>
                        
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/'><p>Home</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/insurance'><p>Insurance</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/contact'><p>Contact</p></Link>
                        </LinkRoll>
                    </div>


                    <div className="col">
                        <h3>SERVICES</h3>
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/substance-abuse'><p>Addiction Treatment</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/detox'><p>Detox Programs</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/jobs'><p>Employment Assistance</p></Link>
                        </LinkRoll>
                    </div>

                    <div className="col">
                        <h3>Location</h3>
                        <p>25 Mitchell Blvd, Suite 2, San Rafael, <br/> CA. 94903 </p>
                        <p>Info@SanRafaelRehab.com</p>
                        <p><a href="tel:(323) 524-3328"> +1 (323) 524-3328</a></p>
                    </div>


                    <div className="certification">

                        <div className="certificationLeft">
                            <h3>Certified by the State Department of Health Care Services</h3>
                            <p>Certification Number: 210056AP <br />Expiration Date: 9/30/2026</p>
                            <br />
                            <a href="https://data.chhs.ca.gov/dataset/sud-recovery-treatment-facilities"
                            target="_blank"
                            rel="noreferrer">( View License & Certification Information here ).</a>
                                <div className='certificationLeftImage'>
                                    <img
                                    src={DHCS}
                                    alt="DHCS Logo"
                                    loading="lazy"
                                    />
                                </div>
                        </div>
{/* 
                        <div className="certificationRight">
                            <h3>San Rafael Rehab is accredited by The Joint Commission and have been awarded its Gold Seal of Approval™</h3>
                            
                                <div className='certificationRightImage'>
                                    <img
                                    src={JointCommission}
                                    alt="Joint Commission Logo"
                                    loading="lazy"
                                    />
                                </div>
                        </div> */}

</div>


                </div>
                <p className='copyright'><p>&copy; 2025 San Rafael Rehab. All rights reserved.</p></p>


            </div>

        </div>
</>
    )
}

export default Footer
