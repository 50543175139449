import React from 'react'
import Fade from 'react-reveal/Fade';
import './Stimulant.css'
import {Helmet} from "react-helmet";

const Stimulant = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stimulant Drugs Addiction - San Rafael Rehab</title>
            <link rel="canonical" href="https://www.sanrafaelrehab.org/stimulant" />
        </Helmet>


        <div className='stimulant'>
                <div className="stimulant-content">
                    <Fade top>
                        <h1> STIMULANT DRUGS</h1>
                        <h3>SUBSTANCE ABUSE</h3>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Stimulant
